define("irene/helpers/is-equal-time", ["exports", "ember-date-components/helpers/is-equal-time"], function (_exports, _isEqualTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isEqualTime.default;
    }
  });
  Object.defineProperty(_exports, "isEqualTime", {
    enumerable: true,
    get: function () {
      return _isEqualTime.isEqualTime;
    }
  });
});
