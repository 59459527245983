export default {
  "file-report-btn": "_file-report-btn_b3hpus",
  "btn-container": "_btn-container_b3hpus",
  "progress-loader": "_progress-loader_b3hpus",
  "report-list-dropdown": "_report-list-dropdown_b3hpus",
  "dd-trigger": "_dd-trigger_b3hpus",
  "toggle-icon": "_toggle-icon_b3hpus",
  "prev-report": "_prev-report_b3hpus",
  "prev-report-item": "_prev-report-item_b3hpus",
  "download-pre-report": "_download-pre-report_b3hpus",
  "generated-label": "_generated-label_b3hpus"
};
