import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import ENUMS from 'irene/enums';
import ENV from 'irene/config/environment';
import { t } from 'ember-intl';
import { task } from 'ember-concurrency';

export default Component.extend({
  intl: service(),
  ajax: service(),
  notify: service('notifications'),

  tSavedPreferences: t("savedPreferences"),
  tRiskAndCommentRequired: t("riskAndCommentRequired"),

  isEditing: false,
  selectedRisk: null,

  risks: computed(function() {
    const risks = ENUMS.RISK.CHOICES;
    const riskFilter = [ENUMS.RISK.NONE, ENUMS.RISK.UNKNOWN];
    return risks.filter(risk => !riskFilter.includes(risk.value));
  }),

  vulnerabilityDetails: computed('store', 'vulnerability.id', function() {
    return this.get("store").peekRecord('vulnerability', this.get('vulnerability.id'));
  }),

  riskOverridden: computed("vulnerability.riskOverridden", function() {
    const riskOverridden = this.get("vulnerability.riskOverridden");
    return riskOverridden;
  }),

  setProperties(data,type) {
    if(type === "mark") {
      this.set("vulnerability.risk", data.risk);
      this.set("vulnerability.comment", data.comment);
      this.set("vulnerability.riskOverridden", true);
      this.set("riskOverridden", true);
    }
    else {
      this.set("resettingPreference", false);
      this.set("isEditing", false);
      this.set("riskOverridden", false);
      this.set("vulnerability.risk", data.risk);
      this.set("vulnerability.comment", data.comment);
    }
  },

  saveVulnerabilityPreferences: task(function*(data, type) {
    const profileId = this.get("project.activeProfileId");
    const vulnerabilityId = this.get("vulnerability.id");
    const tSavedPreferences = this.get("tSavedPreferences");
    const url = [ENV.endpoints.profiles, profileId, ENV.endpoints.vulnerabilityPreferences, vulnerabilityId].join('/');
    try {
      yield this.get("ajax").put(url, {data});
      this.get("notify").success(tSavedPreferences);
      this.setProperties(data,type);
      this.set("isEditing", false);
      this.setProperties(data,type);
    } catch (error) {
      this.get("notify").error(error.payload.message);
    }
  }),

  actions: {
    selectPreference() {
      this.set("isEditing", true);
      this.set("selectedRisk", parseInt(this.$('#marked-analysis-project')[0].value));
    },

    savePreference() {
      const risk = this.get("selectedRisk") || this.get("vulnerability.risk");
      const comment = this.get("vulnerability.comment");
      if (!risk || !comment) {
        this.get("notify").error(this.get("tRiskAndCommentRequired"));
        return;
      }

      const type = "mark";
      const data = {
        risk,
        comment
      };
      this.get('saveVulnerabilityPreferences').perform(data, type);
    },

    editPreference() {
      this.set("isEditing", true);
    },

    cancelEdit() {
      this.set("isEditing", false);
    },

    resetPreference() {
      const type = "reset";
      const data = {
        risk: null,
        comment: null
      };
      this.get('saveVulnerabilityPreferences').perform(data, type);
    },

  }
});
