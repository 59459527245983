import faker from 'faker';
import Base from './base';
import ENUMS from 'irene/enums';


export default Base.extend({
  name: faker.lorem.sentence,
  description: faker.lorem.sentence,
  question: faker.lorem.sentence,
  successMessage: faker.lorem.sentence,
  moreInfo: faker.lorem.sentence,
  heading: faker.lorem.sentence,
  intro: faker.lorem.sentence,
  complaint: faker.lorem.sentence,
  nonComplaint: faker.lorem.sentence,
  relatedTo: faker.lorem.sentence,
  businessImplication: faker.lorem.sentence,

  types(){
    var t = ENUMS.VULNERABILITY_TYPE;
    return [t.STATIC, t.DYNAMIC, t.MANUAL];
  }
});
