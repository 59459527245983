import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  me: service('me'),
  vulnerabilityPreferences: computed('project.activeProfileId', 'store', function() {
    return this.get("store").query('vulnerability-preference', {id: this.get("project.activeProfileId")});
  })

});
