define("irene/helpers/dayjs-format", ["exports", "ember-dayjs-helpers/helpers/dayjs-format"], function (_exports, _dayjsFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "dayjsFormat", {
    enumerable: true,
    get: function () {
      return _dayjsFormat.dayjsFormat;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dayjsFormat.default;
    }
  });
});
