export default {
  "org-setting-title": "_org-setting-title_1ouy50",
  "org-setting-subtitle": "_org-setting-subtitle_1ouy50",
  "org-setting-desc": "_org-setting-desc_1ouy50",
  "dropzone": "_dropzone_1ouy50",
  "dropzone-logo": "_dropzone-logo_1ouy50",
  "dropzone-upload": "_dropzone-upload_1ouy50",
  "dropzone-upload-active": "_dropzone-upload-active_1ouy50",
  "sso-table": "_sso-table_1ouy50",
  "idp-cert-icon": "_idp-cert-icon_1ouy50",
  "checkbox": "_checkbox_1ouy50"
};
