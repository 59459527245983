export default {
  "dynamicscan-automation-settings": "_dynamicscan-automation-settings_1oy3dy",
  "flag-label": "_flag-label_1oy3dy",
  "flag-label-icon": "_flag-label-icon_1oy3dy",
  "script": "_script_1oy3dy",
  "script-info": "_script-info_1oy3dy",
  "script-icon": "_script-icon_1oy3dy",
  "script-name": "_script-name_1oy3dy",
  "script-invalid": "_script-invalid_1oy3dy",
  "script-invalid-icon": "_script-invalid-icon_1oy3dy"
};
