import commondrf from '../commondrf';

export default class Vulnerability extends commondrf {
  namespace = "api/hudson-api"
  _buildURL(modelName, id) {
    const baseURL = this.buildURLFromBase(`${this.get('namespace')}/projects`);
    if (id) {
      return `${baseURL}/${encodeURIComponent(id)}`;
    }
    return baseURL;
  }

  _buildNestedURL(modelName, projectId, id) {
    const projectURL = this._buildURL(modelName, projectId);
    const vulnerabilitiesURL = [projectURL, 'vulnerabilities'].join('/');
    if (id) {
      return `${vulnerabilitiesURL}/${encodeURIComponent(id)}`;
    }
    return vulnerabilitiesURL;
  }

  urlForQuery(query, modelName) {
    return this._buildNestedURL(modelName, query.projectId);
  }
}
