define("irene/helpers/dayjs-weekdays-short", ["exports", "ember-dayjs-helpers/helpers/dayjs-weekdays-short"], function (_exports, _dayjsWeekdaysShort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "dayjsWeekdaysShort", {
    enumerable: true,
    get: function () {
      return _dayjsWeekdaysShort.dayjsWeekdaysShort;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dayjsWeekdaysShort.default;
    }
  });
});
