import { Factory } from 'ember-cli-mirage';

export default Factory.extend({

  totalCritical: 5,
  totalHigh: 4,
  totalMedium: 3,
  totalLow: 2

});
