import Model, { attr }  from '@ember-data/model';
import ENUMS from 'irene/enums';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default Model.extend({
  risk: attr('number'),
  comment: attr('string'),

  riskOverridden: computed('risk', function() {
    if(!isEmpty(this.get("risk"))){
      return true;
    }
  }),

  iconClass(risk) {
    switch (risk) {
      case ENUMS.RISK.UNKNOWN: return "fa-spinner fa-spin";
      case ENUMS.RISK.NONE: return "fa-check";
      case ENUMS.RISK.CRITICAL: case ENUMS.RISK.HIGH: case ENUMS.RISK.LOW: case ENUMS.RISK.MEDIUM:  return "fa-warning";
    }
  },

  riskIconClass: computed('risk', function() {
    return this.iconClass(this.get("risk"));
  }),

  labelClass(risk) {
    const cls = 'tag';
    switch (risk) {
      case ENUMS.RISK.UNKNOWN: return `${cls} is-progress`;
      case ENUMS.RISK.NONE: return `${cls} is-success`;
      case ENUMS.RISK.LOW: return `${cls} is-info`;
      case ENUMS.RISK.MEDIUM: return `${cls} is-warning`;
      case ENUMS.RISK.HIGH: return `${cls} is-danger`;
      case ENUMS.RISK.CRITICAL: return `${cls} is-critical`;
    }
  },

  riskLabelClass: computed('risk', function() {
    return this.labelClass(this.get("risk"));
  })
});
