export default {
  "operational-status": "_operational-status_i5mf6y",
  "operational-text": "_operational-text_i5mf6y",
  "online": "_online_i5mf6y",
  "unavailable-text": "_unavailable-text_i5mf6y",
  "status-label": "_status-label_i5mf6y",
  "system-status-container": "_system-status-container_i5mf6y",
  "system-status-box": "_system-status-box_i5mf6y",
  "loading-indicator": "_loading-indicator_i5mf6y",
  "system-status-table": "_system-status-table_i5mf6y"
};
