define("irene/helpers/days-in-month", ["exports", "ember-dayjs-helpers/helpers/days-in-month"], function (_exports, _daysInMonth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "daysInMonth", {
    enumerable: true,
    get: function () {
      return _daysInMonth.daysInMonth;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _daysInMonth.default;
    }
  });
});
