import ENV from 'irene/config/environment';

export default function installHotjar() {
  if (!ENV.enableHotjar) {
    // eslint-disable-next-line no-console
    return console.log("Hotjar Disabled");
  }
  (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments);};
      h._hjSettings={hjid:225284,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}
