define("irene/helpers/dayjs-add", ["exports", "ember-dayjs-helpers/helpers/dayjs-add"], function (_exports, _dayjsAdd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "dayjsAdd", {
    enumerable: true,
    get: function () {
      return _dayjsAdd.dayjsAdd;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dayjsAdd.default;
    }
  });
});
