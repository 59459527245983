define("irene/initializers/ember-pace", ["exports", "@appknox/ember-pace/initializers/ember-pace"], function (_exports, _emberPace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emberPace.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _emberPace.initialize;
    }
  });
});
