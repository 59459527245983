import Model, { attr }  from '@ember-data/model';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';

const Vulnerability = Model.extend({
  name: attr('string'),
  uuid: attr('string'),
  description: attr('string'),
  question: attr('string'),
  successMessage: attr('string'),
  moreInfo: attr('string'),
  heading: attr('string'),
  intro: attr('string'),
  complaint: attr('string'),
  platform: attr('number'),
  nonComplaint: attr('string'),
  relatedTo: attr('string'),
  businessImplication: attr('string'),
  types: attr(),

  descriptionUnescapedd: computed('description', function() {
    return htmlSafe(this.get('description'));
  })
});

export default Vulnerability;
