define("irene/helpers/dayjs-day-of-week", ["exports", "ember-dayjs-helpers/helpers/dayjs-day-of-week"], function (_exports, _dayjsDayOfWeek) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "dayjsDayOfWeek", {
    enumerable: true,
    get: function () {
      return _dayjsDayOfWeek.dayjsDayOfWeek;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dayjsDayOfWeek.default;
    }
  });
});
