define("irene/helpers/dayjs-is-leap-year", ["exports", "ember-dayjs-helpers/helpers/dayjs-is-leap-year"], function (_exports, _dayjsIsLeapYear) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "dayjsIsLeapYear", {
    enumerable: true,
    get: function () {
      return _dayjsIsLeapYear.dayjsIsLeapYear;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dayjsIsLeapYear.default;
    }
  });
});
